import(/* webpackMode: "eager" */ "/app/src/components/domain/organization/client/organization-contact-form.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/domain/organization/client/organization-form.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/domain/organization/client/organization-logo-upload.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/domain/organization/client/organization-member-edit-dialog.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/domain/organization/client/organization-member-table.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/domain/organization/client/organization-metadata-form.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/domain/organization/client/organization-provider/organization-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/domain/organization/client/organization-theme-loader.tsx");
