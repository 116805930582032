"use client";

import { type ReactNode, createContext, useContext, useState } from "react";
import { type FileWithPreview } from "~/components/ui/image-select";
import { type FileUploadMetadata, type MutateAsyncReturnType, useUploadToObjectStorageAndStoreFile } from "~/lib/hooks";
type Props = {
  children?: ReactNode;
};
type UploadImageContextType = {
  dialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;
  selectedFile: FileWithPreview | null;
  setSelectedFile: (file: FileWithPreview | null) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  storeAndUploadFile: (metadata: FileUploadMetadata | undefined) => Promise<MutateAsyncReturnType | undefined>;
};
export const UploadImageContext = createContext<UploadImageContextType | undefined>(undefined);
export const UploadImageProvider = ({
  children
}: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<FileWithPreview | null>(null);
  const [loading, setLoading] = useState(false);
  const storeAndUploadFileMutation = useUploadToObjectStorageAndStoreFile();
  async function storeAndUploadFile(metadata: FileUploadMetadata | undefined = {}) {
    if (!selectedFile) {
      return;
    }
    if (loading) {
      return;
    }
    setLoading(true);
    const storeAndUploadFileResult = await storeAndUploadFileMutation.mutateAsync({
      file: selectedFile,
      metadata: metadata
    });
    setLoading(false);
    return storeAndUploadFileResult;
  }
  return <UploadImageContext.Provider value={{
    dialogOpen,
    setDialogOpen,
    selectedFile,
    setSelectedFile,
    loading,
    setLoading,
    storeAndUploadFile
  }} data-sentry-element="unknown" data-sentry-component="UploadImageProvider" data-sentry-source-file="useUploadImage.tsx">
      {children}
    </UploadImageContext.Provider>;
};

// Define the useLayout hook
export const useUploadImage = (): UploadImageContextType => {
  const context = useContext(UploadImageContext);
  if (!context) {
    throw new Error("useUploadImage must be used within UploadImageProvider");
  }
  return context;
};